<template>
  <div>
    <div v-if="isshowgrid">
      <va-card title="Vendor List">
        <div class="row">
          <div class="flex md6 mt-2">
            <va-input class="va-input-search-container"
              :value="term"
              placeholder="search"
              @input="search"
              removable
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex md6 text-right">
            <va-button
	      v-if='!supportRole'
              icon="fa fa-plus"
              class="my-0"
              @click.prevent="add()">
              Create
            </va-button>
          </div>
        </div>
        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"
          style="max-height: 90vh;">
          <template slot="actions" slot-scope="props">
            <va-button
	      v-if='!supportRole'
              flat
              small
              color="gray"
              icon="fa fa-pencil"
              @click="edit(props.rowData)"
              class="ma-0">
            </va-button>
          </template>
          <template slot="action" slot-scope="props">
            <va-button
	      v-if='!supportRole'
              flat
              small
              color="red"
              icon="fa fa-remove"
              @click="remove(props.rowData)"
              class="ma-0">
            </va-button>
          </template>

        </va-data-table>
        <va-modal
          v-model="showRemoveModal"
          title="Delete Vendor"
          size='small'
          :message="msg"
          okText="Confirm"
          cancelText="Cancel"
          @ok="deleteVendor(entity)"
          @cancel="cancel()">
        </va-modal>
      </va-card>
    </div>
    <div v-if="isshowForm">
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Vendor Name</span>
            <va-input
              v-model.trim="vendor_name"
              type="text"
              v-if="isCreate || isUpdate"
              placeholder="Enter Vendor Name"
              :error="!!VendorNameErrors.length"
              :error-messages="VendorNameErrors"
            />
            <span class="va-form-label va-form-required-label">Vendor Code</span>
            <va-input
              v-model.trim="vendor_code"
              type="text"
              v-if="isCreate || isUpdate"
              placeholder="Enter Vendor Code"
              :error="!!VendorCodeErrors.length"
              :error-messages="VendorCodeErrors"
            />
            <span class="va-form-label va-form-required-label">Make</span>
            <va-input
              v-model.trim="make"
              type="text"
              v-if="isCreate || isUpdate"
              placeholder="Please Enter Make"
              :error="!!MakeErrors.length"
              :error-messages="MakeErrors"
            />
            <span class="va-form-label va-form-required-label">Model</span>
            <va-input
              v-model.trim="model"
              type="text"
              v-if="isCreate || isUpdate"
              placeholder="Enter Model"
              :error="!!ModelErrors.length"
              :error-messages="ModelErrors"
            />
            <span class="va-form-label va-form-required-label">IP</span>
            <va-input
              v-model.trim="ip"
              type="text"
              v-if="isCreate || isUpdate"
              placeholder="Enter Your IP"
              :error="!!IpErrors.length"
              :error-messages="IpErrors"
            />
            <span class="va-form-label va-form-required-label">Prefix</span>
            <va-input
              v-model.trim="prefix"
              type="text"
              v-if="isCreate || isUpdate"
              placeholder="Enter Your IP"
              :error="!!PrefixErrors.length"
              :error-messages="PrefixErrors"
            />
            <span class="va-form-label va-form-required-label">Starting Serial Number</span>
            <va-input
              v-model.trim="start_serial_no"
              type="text"
              v-if="isCreate || isUpdate"
              placeholder="Enter Your IP"
              :error="!!StartSerialNoErrors.length"
              :error-messages="StartSerialNoErrors"
            />
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createVendor()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateVendor()">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import REGEX from '../../i18n/regex_pattern'
Vue.use(vueResource)

export default {
  name: 'app_setting',
  beforeCreate () {
    var role = this.$cookies.get('userRole')
    const loader = Vue.$loading.show({ width: 40, height: 40 })
    this.$http.get(config.menu.host + '/vendor')
      .then(response => {
        loader.hide()
        this.vendor_list = response.body
        this.isshowgrid = true
	if(role=="SUPPORT"){
          this.supportRole=true
        }
        // this.getAllVendor()
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({ text: error.body, type: 'error' })
        }
      })
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      vendor_list: [],
      vendor_name: '',
      VendorNameErrors: [],
      vendor_code: '',
      VendorCodeErrors: [],
      make: '',
      MakeErrors: [],
      model: '',
      ModelErrors: [],
      ip: '',
      IpErrors: [],
      manufacture_serial_no: '',
      ManufactureSerialNoErrorsL: [],
      prefix: '',
      PrefixErrors: [],
      start_serial_no: '',
      StartSerialNoErrors: [],
      isshowgrid: true,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      showRemoveModal: false,
      msg: '',
      supportRole:false
    }
  },
  computed: {
    formReady () {
      return !this.VendorNameErrors.length &&
             !this.VendorCodeErrors.length &&
             !this.MakeErrors.length &&
             !this.ModelErrors.length &&
             !this.IpErrors.length && 
             !this.PrefixErrors.length && 
             !this.StartSerialNoErrors.length
    },
    fields () {
      return [{
        name: 'vendor_name',
        title: 'Vendor Name',
      },
      {
        name: 'make',
        title: 'Make',
      },
      {
        name: 'model',
        title: 'Model',
      },
      {
        name: 'ip',
        title: 'IP',
      },
      {
        name: 'prefix',
        title: 'Prefix'
      },
      {
        name: 'starting_serial_no',
        title: 'Starting Serial No'
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      },
      {
        name: '__slot:action',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.vendor_list)
    },
  },
  methods: {
    getvendor () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/vendor')
        .then(response => {
          loader.hide()
          this.vendor_list = response.body
          this.isshowgrid = true
        }, error => {
          loader.hide()
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          }
        })
    },
    createVendor () {
      this.VendorNameErrors = this.vendor_name ? [] : ['Vendor Name is required']
      this.VendorCodeErrors = this.vendor_code ? [] : ['Vendor Code is required']
      this.MakeErrors = this.make ? [] : ['Make is required']
      this.ModelErrors = this.model ? [] : ['Model is required']
      this.IpErrors = this.ip ? [] : ['IP is required']
      this.PrefixErrors = this.prefix ? [] : ['prefix is required']
      this.StartSerialNoErrors = this.start_serial_no ? [] : ['Serial no is required']

      if (!this.formReady) {
        return
      }

      var payload = {
        vendor_name: this.vendor_name,
        vendor_code: this.vendor_code,
        make: this.make,
        model: this.model,
        ip: this.ip,
        prefix: this.prefix,
        starting_serial_no: this.start_serial_no
      }

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/vendor', payload)
        .then(responseData => {
          loader.hide()
          Vue.notify({ text: responseData.body, type: 'success' })
          this.list()
          this.getvendor()
        }, err => {
          loader.hide()
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' })
          }
        })
    },
    updateVendor () {
      this.VendorNameErrors = this.vendor_name ? [] : ['Vendor Name is required']
      this.VendorCodeErrors = this.vendor_code ? [] : ['Vendor Code is required']
      this.MakeErrors = this.make ? [] : ['Make is required']
      this.ModelErrors = this.model ? [] : ['Model is required']
      this.IpErrors = this.ip ? [] : ['IP is required']
      this.PrefixErrors = this.prefix ? [] : ['prefix is required']
      this.StartSerialNoErrors = this.start_serial_no ? [] : ['Serial no is required']

      if (!this.formReady) {
        return
      }

      var payload = {
        vendor_name: this.vendor_name,
        vendor_code: this.vendor_code,
        make: this.make,
        model: this.model,
        ip: this.ip,
        prefix: this.prefix,
        starting_serial_no: this.start_serial_no
      }

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/vendor/' + this.vendor_id, payload)
        .then(responseData => {
          loader.hide()
          Vue.notify({ text: responseData.body, type: 'success' })
          this.list()
          this.getvendor()
        }, err => {
          loader.hide()
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' })
          }
        })
    },
    edit (row) {
      this.title = 'Update Vendor'
      this.vendor_id = row.vendor_id
      this.vendor_name = row.vendor_name
      this.vendor_code = row.vendor_code
      this.make = row.make
      this.model = row.model
      this.ip = row.ip
      this.manufacture_serial_no = row.manufacture_serial_no
      this.start_serial_no = row.starting_serial_no
      this.prefix = row.prefix
      this.VendorNameErrors = []
      this.VendorCodeErrors = []
      this.MakeErrors = []
      this.ModelErrors = []
      this.IpErrors = []
      this.StartSerialNoErrors = []
      this.PrefixErrors = []
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
    remove (row) {
      this.msg = 'Are you sure to delete the vendor ' + row.vendor_name + ' ?'
      this.entity = row
      this.showRemoveModal = true
    },
    cancel () {
      this.showRemoveModal = false
    },
    deleteVendor (data) {
      this.$http.delete(config.menu.host + '/vendor/' + data.vendor_id).then(resp => {
        Vue.notify({ text: resp.body, type: 'success' })
        this.list()
        this.getvendor()
      }, err => {
        if (err && err.body) {
          Vue.notify({ text: err.body, type: 'error' })
        }
      })
    },
    add () {
      this.title = 'Create Vendor'
      this.vendor_name = ''
      this.vendor_code = ''
      this.make = ''
      this.model = ''
      this.ip = ''
      this.prefix = ''
      this.start_serial_no = ''
      this.VendorNameErrors = []
      this.VendorCodeErrors = []
      this.MakeErrors = []
      this.ModelErrors = []
      this.IpErrors = []
      this.PrefixErrors = []
      this.StartSerialNoErrors = []
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
    },
    list () {
      this.getvendor()
      this.isshowgrid = true
      this.isshowForm = false
    },
    search: function (term) {
      this.term = term
    },
  },
}
</script>

<style lang="scss">
</style>
